<template>
  <div class="loginBg">
    <div class="loginCenter">
      <img src="../assets/loginImg/login.png" alt="" class="mainIcon" />
      <h1>呼和浩特市人民检察院青检听证管理平台</h1>
      <div class="loginBox">
        <el-form
          :model="ruleForm"
          status-icon
          :rules="rules"
          ref="manualVarifaction"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item prop="account">
            <span class="userIcon"></span>
            <el-input
              v-model="ruleForm.account"
              placeholder="请输入用户名"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <span class="passwordIcon"></span>
            <el-input
              type="password"
              v-model="ruleForm.password"
              placeholder="请输入密码"
            ></el-input>
          </el-form-item>
          <el-form-item prop="status">
            <JcRange class="JcRange" ref="slider"></JcRange>
          </el-form-item>
          <el-button :loading="!flag" type="primary" @click="submitForm"
            >登 录</el-button
          >
        </el-form>
      </div>
    </div>
    <p>朗正集成 技术支持</p>
  </div>
</template>

<script>
import { Message } from "element-ui";

import JcRange from "../components/login/JcRange.vue";
import md5 from "md5";
import { login } from "../api/api";
export default {
  data() {
    return {
      ruleForm: {
        account: "",
        password: "",
      },
      rules: {
        account: [
          { required: true, message: "用户名不能为空！", trigger: "blur" },
        ],
        password: [
          { required: true, message: "密码不能为空！", trigger: "blur" },
        ],
      },
      flag: true,
    };
  },
  components: { JcRange },

  methods: {
    submitForm() {
      if (!this.flag) return;
      this.flag = false;
      this.$refs["manualVarifaction"].validate((valid) => {
        if (!valid) {
          this.flag = true;
          return;
      }
        console.log(this);
        if (this.$refs["slider"]) {
          // 未通过验证时，提示错误信息并返回
          if (!this.$refs["slider"].confirmSuccess) {
            Message.error("请拖动滑块验证");
            this.flag = true;
            return;
          }
        }
        let obj = {};
        obj.password = md5(this.ruleForm.password);
        // obj.password = this.ruleForm.password;
        obj.account = this.ruleForm.account;
        login(obj).then((data) => {
          if (data.code == 200) {
            this.$store.commit("setUserInfo", data.data);
            localStorage.setItem(
              "tltz_userinfo",
              JSON.stringify(data.data)
            );
            localStorage.setItem(
              "permissionidList",
              JSON.stringify(data.data.perlist)
            );
            // localStorage.setItem(
            //   "permissionid",
            //   JSON.stringify(data.data.permissionid)
            // );
            localStorage.setItem("tltz_token", data.data.token);
            if (this.$route.query.from) {
              this.$router.replace(this.$route.query.from);
            } else {
              this.$router.replace("/");
            }
          } else {
            Message.error(data.msg);
            this.flag = true;
          }
        });
      });
    },
  },
};
</script>
<style lang='less'>
.loginBg {
  min-height:900px;
  min-width:1920px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  overflow: hidden;
  background-image: url("../assets/loginImg/background.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  .loginCenter {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: -webkit-flex; /* Safari */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .mainIcon {
      width: 139px;
      height: 143px;
    }
    h1 {
      font-size: 52px;
      color: #fff;
      padding: 40px 0 50px 0;
      font-family: Microsoft YaHei;
      font-weight: bold;
    }
    .loginBox {
      width: 710px;
      height: 445px;
      background-image: url("../assets/loginImg/lgBorder.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      .el-form {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .el-form-item {
          box-sizing: border-box;
          border: 2px solid #02ffff;
          border-radius: 4px;
          font-size: 16px;
          width: 514px;
          height: 71px;
          overflow: hidden;
          .el-form-item__content {
            display: flex;
            align-items: center;
            width: 100%;
            margin-left: 0 !important;
            .userIcon {
              display: inline-block;
              width: 35px;
              height: 35px;
              background-image: url(../assets/loginImg/userIcon.png);
              background-size: 100% 100%;
              margin-left: 20px;
            }
            .passwordIcon {
              width: 35px;
              height: 35px;
              background-image: url(../assets/loginImg/passwordIcon.png);
              background-size: 100% 100%;
              margin-left: 20px;
            }
            input {
              background: transparent !important;
              border: none !important;
              height: 71px;
              caret-color: #999999 !important;
              font-size: 22px !important;
              color: #fff;
            }
          }
        }
        .el-button {
          width: 514px;
          height: 71px;
          background: #0384ea;
          border-radius: 4px;
          font-size: 32px;
        }
      }
    }
  }

  p {
    width: 100%;
    font-size: 20px;
    color: #fff;
    position: absolute;
    bottom: 30px;
    text-align: center;
    font-weight: 300;
  }
//解决谷歌默认登录样式
  input {
    background-color: rgba(255, 255, 255, 0) !important;
  }
  input:-webkit-autofill {
    transition: background-color 5000s ease-in-out 0s;
  }
  input:-webkit-autofill:focus {
    -webkit-text-fill-color: #333 !important;
  }
}
</style>
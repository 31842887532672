import myhttp from './http'
// 登录
export function login(params) {
    return myhttp.post('/back/login', params)
}
//校验token

export function isTok() {
    return myhttp({
        url: '/user/isTok',
        method: "GET",
    });
}
export const transformTime  = (time) => {
    var date = new Date(time);
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? "0" + m : m;
    var d = date.getDate();
    d = d < 10 ? "0" + d : d;
    // var h = date.getHours();
    // h = h < 10 ? "0" + h : h;
    // var minute = date.getMinutes();
    // minute = minute < 10 ? "0" + minute : minute;
    // var s = date.getSeconds();
    // s = s < 10 ? "0" + s : s;
    return y + "-" + m + "-" + d;
}
//修改密码
export function updatePassword(params) {
    return myhttp.post(
        '/Login/updatePassword',
        params
    );
}

//校验旧密码
export function OldPasswordCheck(params) {
    return myhttp.post(
        '/Login/OldPasswordCheck',
        params
    );
}

//获取权限列表
export function getPermissionsList() {
    return myhttp({
        url: '/per/selectPermissionsInfo',
        method: "GET",
    });
}
// //查询角色类型
// export function getTypeList(adminid,procuratorateid,keyword) {
//     return myhttp({
//         url: '/types/selectTypesBg',
//         method: "POST",
//         params: { adminid,procuratorateid,keyword }
//     });
// }
//用户列表
export function getTypeList(adminid,procuratorateid,keyword) {
    return myhttp({
        url: "/types/selectTypesBg",
        method: "POST",
        params: { adminid,procuratorateid,keyword }
    });
}



//一个用户的权限变更
export function updateTypes(params) {
    return myhttp.post(
        '/types/updateTypes',
        params
    );
}


//删除角色类型
export function deleteTypes(id) {
    return myhttp({
        url:'/types/deleteTypes',
        method: "POST",
        params: { id }
    });
}

//添加角色类型
export function insertTypes(params) {
    return myhttp.post(
        '/types/insertTypes',
        params
    );
}

